import React, { Component } from "react";
import * as Const from "../Constant";
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import FavoriteBorder from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CardHeader from '@mui/material/CardHeader';
import ChoixSelection from "./ChoixSelection";
import ChoixCondition from "./ChoixCondition";
import Divider from '@mui/material/Divider';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PanelResultat from "./PanelResultat";
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import Drawer from '@mui/material/Drawer';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import TableauPronostics from "./TableauPronostics";
import FiltrePronostics from "./FiltrePronostics";
import DialogResultat from "./DialogResultat";
import Tooltip from '@mui/material/Tooltip';
import DialogOnboardPronostics from "./DialogOnboardPronostics";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import AlertTitle from '@mui/material/AlertTitle';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import ModuleSyntheseMax from './ModuleSyntheseMax';


export default class Pronostics extends Component {

    constructor(props) {
        super(props);
        this.state = {
          pronostics: null,
          loader: false,
          openDialog: false,
          result:null,
          zoom: false,
          taillePanelFiltre: "30vw",
          taillePanel: "70vw",
          syntheseMax: "N"
        }

        this.selection = "";
        this.dossier = "Pronostics Trotstats";
        this.label = "";
        this.actif = "";
        this.pronoJour = false;
    }

    select = (id) => {
      this.setState({loader: true});
      fetch(
        Const.URL_WS_METHODE_EXEC + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&methodeID=${id}`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({loader: false});
          this.setState({result: data});
          this.setState({openDialog: true});
        })
        .catch((error) => {
          console.log("Request failed", error);
          this.setState({loader: false});
        });
    }

    componentWillReceiveProps(props) {        
    }

    componentDidMount() {
      this.auth();
    }

    resize = () => {
      if(this.state.zoom){
        this.setState({zoom: false});
        this.setState({taillePanelFiltre: '30vw'});
        this.setState({taillePanel: '70vw'});
      }else{
        this.setState({taillePanelFiltre: '0vw'});
        this.setState({taillePanel: '100vw'});
        this.setState({zoom: true});
      } 
    }

    auth = () => {
      let user = localStorage.getItem("utilisateur_dtf");
      let pass = localStorage.getItem("pass_dtf");
      fetch(
        Const.URL_WS_AUTH + `?email=${user}&password=${pass}&ipClient=`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
         
          if(!data.register){
            window.location.href = Const.URL + "synthese";
          }else{
            this.setState({syntheseMax: data.moduleSyntheseMax});
            this.callPronostics();
          }
        })
        .catch((error) => {
          console.log("Request failed", error);
          this.setState({loader: false});
        });
    }

    closeDialog = () => {
      this.setState({openDialog: false});
    }

    deletePronostics = (id) => {
      fetch(
        Const.URL_WS_METHODE_DELETE + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&id=${id}`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
          this.callPronostics();
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }

    updateActif = (id) => {
      fetch(
        Const.URL_WS_METHODE_ACTIF + `?id=${id}`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
          this.callPronostics();
        })
        .catch((error) => {
          console.log("Request failed", error);
        });
    }

    callPronostics = () => {
      this.setState({loader: true});
      fetch(
        Const.URL_WS_METHODE_PRONOSTICS + `?utilisateur=${localStorage.getItem("utilisateur_dtf")}&dossier=${this.dossier}&label=${this.label}
        &selection=${this.selection}
        &dynamique=${this.dynamique}
        &reussiteGagnant=${this.reussiteGagnant}
        &reussitePlace=${this.reussitePlace}
        &pronoJour=${this.pronoJour}`,
        { retry: 0, retryDelay: 1000 }
      )
        .then((res) => res.json())
        .then((data) => {
          this.setState({pronostics:data.pronostics});
          this.setState({loader: false});
        })
        .catch((error) => {
          console.log("Request failed", error);
          this.setState({loader: false});
        });
    }

    redirectHome = () => {
      window.location.href = Const.URL + "synthese";
    }

    redirectEditeur = () => {
      window.location.href = Const.URL + "editeur";
    }

    activeAide =() => {
      localStorage.setItem("onboardPronostics", 0);
      window.location.href = Const.URL + "pronostics";
    }

    filtres = (dossier, label, selection, dynamique, reussiteGagnant, reussitePlace, pronoJour) => {
     this.dossier = dossier;
     this.label = label;
     this.selection = selection;
     this.dynamique = dynamique;
     this.reussiteGagnant = reussiteGagnant;
     this.reussitePlace = reussitePlace;
     this.pronoJour = pronoJour;
      
    this.callPronostics();
    }

   
    render() {
        return (
            <div>

              {(localStorage.getItem("onboardPronostics") == null || localStorage.getItem("onboardPronostics") == 0) && (
                <DialogOnboardPronostics titre={"onboardPronostics"} open={true} cookie={"onboardPronostics"}/>
              )}

               <Box sx={{ width: '100vw', maxWidth: '100vw', height:'55px', maxHeight:'55px', bgcolor: 'white' , overflow:'hidden', overflowX:'hidden', borderBottomWidth:"5px", borderBottomColor:"grey", borderBottomStyle:"inset" }}>
               <Grid container spacing={1}>
               <Grid item xs={6}>
               <div style={{display:"flex"}}>
                  <div>
                    <img style={{marginTop:"0.2em", marginLeft:"1em"}} width='33' height={45} src={"https://dataturf.fr/images/logo_noir.png"}></img>
                  </div>
                  <div style={{marginTop:"0.7em"}}>
                      <span style={{fontFamily:"Racing Sans One", fontSize:"25px", marginLeft:"0.5em"}}>TROTSTATS</span> 
                      <span style={{fontFamily:"Racing Sans One", fontSize:"20px", marginLeft:"0.3em"}}>- Pronostics</span> 
                  </div>
                </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{float:"right", margin:"0.5em"}}>
                 
                  <Tooltip title={<span class="lato-regular">Aide</span>}>
                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.activeAide}
                  >
                  <HelpOutlineOutlinedIcon style={{color:"black"}} />
                  </IconButton>
                  </Tooltip>

                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.redirectEditeur}
                  >
                  <Tooltip title={<span class="lato-regular">Ouvrir éditeur</span>}>
                  <ModeEditOutlinedIcon style={{color:"black"}} />
                  </Tooltip>
                  </IconButton>
                   
                  <IconButton
                    aria-label="close"
                    style={{marginLeft:"1em"}}
                    onClick={this.redirectHome}
                  >
                    <Tooltip title={<span class="lato-regular">Retour synthèse</span>}>
                  <CloseIcon style={{color:"black"}} />
                  </Tooltip>
                  </IconButton>
                </div>
                </Grid>
                
                </Grid>
               </Box>
            
            
            <div style={{display:'flex'}}>
            
            <Box sx={{ width: this.state.taillePanelFiltre, maxWidth: this.state.taillePanelFiltre, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: '#FAFAFA' , overflow:'scroll', overflowX:'hidden' }}>
              <div style={{margin:"1em"}}>
                <FiltrePronostics filtres={this.filtres}/>
              </div>
              <div style={{margin:"1em"}}>
                <ModuleSyntheseMax syntheseMax={this.state.syntheseMax} dossier = {this.dossier}/>
              </div>
            </Box>

            <Box sx={{ width: this.state.taillePanel, maxWidth: this.state.taillePanel, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: 'white', overflow:'hidden', overflowX:'hidden' }}>

            <div>
                <Box sx={{ width: this.state.taillePanel, maxWidth: this.state.taillePanel, height:'calc(100vh - 60px)', maxHeight:'calc(100vh - 60px)', bgcolor: '#FAFAFA', overflow:'scroll', overflowX:'hidden' }}>
                
                <div style={{margin:"1em"}}>
        <div style={{marginTop:"1em", marginBottom:"2em"}}>
        <center>
          <span class="lato-bold" style={{fontVariant:"small-caps", fontSize:"17px"}}>résultats de la recherche</span>
          <div style={{float:"right"}}>
          <IconButton onClick={() => this.resize()} aria-label="delete">
            {this.state.zoom && (
               <FullscreenExitOutlinedIcon style={{color:"black"}}/>
            )}
            {!this.state.zoom && (
              <FullscreenOutlinedIcon style={{color:"black"}}/> 
            )}
          </IconButton>
          </div>
          <div style={{marginTop:"1em", width:"70%"}}>
            <Divider/>
          </div>
        </center>
        </div>

        {this.state.pronostics != null && this.state.pronostics.length > 0 && (

          <div style={{marginTop:"1em"}}>
          <Card>
          <CardHeader
          
          title={<div><span class="lato-bold" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Tableau des pronostics</span> <span style={{transform:"none", fontSize:"13px", textTransform:"none"}} class="lato-regular-italic">{this.state.pronostics.length} résultat(s)</span></div>}
          
         />
            <TableauPronostics data={this.state.pronostics} delete={this.deletePronostics} actif={this.updateActif} select={this.select}  />
          </Card>

          {this.dossier == "Pronostics Trotstats" && (
          <Alert style={{marginTop:"1em"}} severity="info">
            <span style={{fontSize:"14px"}} class="lato-regular">
            Les pronostics Trotstats ne peuvent pas être supprimés de la synthèse <i>Trotstats</i>.<br/> Seuls les pronostics personnels peuvent être ajoutés ou retirés pour générer une synthèse personnalisée.
            </span>
          </Alert>
        )}


          </div>
        
        
        )}
         {this.state.pronostics == null || this.state.pronostics.length == 0 && (
          <div style={{marginTop:"1em"}}>
          <Alert severity="info">
          <AlertTitle><span class="lato-regular" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Information</span></AlertTitle>
          <span class="lato-regular" style={{transform:"none", fontSize:"14px", textTransform:"none"}}>Aucun pronostic trouvé. <br/>
          Utilisez l’éditeur pour créer vos premiers pronostics et démarrer votre analyse.
          </span>
          </Alert>
          </div>
         )}
        </div>

                  
                
                    
                </Box>
            </div>
                
            </Box>

            </div>



            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            
            <DialogResultat data={this.state.result} open={this.state.openDialog} closeDialog={this.closeDialog} />
            
            </div>

        )
    }
}