import React, { Component } from "react";
import Box from '@mui/material/Box';
import * as Const from "../Constant";
import Button from '@mui/material/Button';

import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Grid from '@mui/material/Grid';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';
import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Snackbar from '@mui/material/Snackbar';
import Drawer from '@mui/material/Drawer';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import DialogAide from "./DialogAide";
import Checkbox from '@mui/material/Checkbox';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TableauSyntheseMax from "./TableauSyntheseMax";


export default class ModuleSyntheseMax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModule: false,
      aideFiltre: false,
      syntheseMax: this.syntheseMax,
      bilan: null,
      dossier: this.dossier
    };
    
  }


  aideFiltre = () => {
    this.setState({ aideFiltre: true})
  }

  showModule = () => {
    this.syntheseMax()
    
  }

  closeModule = () => {
    this.setState({ showModule: false})
  }

  closeAideFiltre = () => {
    this.setState({ aideFiltre: false})
  }

  componentWillReceiveProps(props) {
    this.setState({ syntheseMax: props.syntheseMax})
    this.setState({ dossier: props.dossier})
  }

  componentDidMount() {
    
  }

   syntheseMax = () => {
        this.setState({loader: true});
        let user = localStorage.getItem("utilisateur_dtf");
        fetch(
          Const.URL_WS_SYNTHESE_MAX + `?utilisateur=${user}`,
          { retry: 0, retryDelay: 1000 }
        )
          .then((res) => res.json())
          .then((data) => {
            this.setState({bilan: data});
            this.setState({ showModule: true})
            this.setState({loader: false});
          })
          .catch((error) => {
            console.log("Request failed", error);
            this.setState({loader: false});
          });
      }

  //let user = localStorage.getItem("utilisateur_dtf");


  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function selection(data){
      return(
      <div align="center">
      <Avatar style={{backgroundColor:"#3f51b5", width:"50px", height:"20px", marginTop:"0.4em", align:"right"}} variant="square">
        <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{format(data)}</span>
      </Avatar>
      </div>
      )
  }

    function reussite(data){
      var color = "";

        if(data < 0){
          data = "-";
        }else if(data > 75){
          color = "#2D7E43";
        }else if(data > 50 && data <= 75){
          color = "#97BA38";
        }else if(data >= 25 && data <= 50){
          color = "#E8B610";
        }else if(data > 10 && data <= 25){
          color = "#D57B1A";
        }else if(data >= 0 && data <= 10){
          color = "#C53419";
        }

        return  (
          <Avatar style={{backgroundColor:color, width:"50px", height:"20px"}} variant="square">
            <span class="lato-regular" style={{ fontSize:"14px", color:"white"}}>{data} %</span>
          </Avatar>
        )
      
    }


    function color(num, syn1, syn2, syn3, syn4, syn5){
      if(num == syn1){
        return "#66B230"
      }

      if(num == syn2){
        return "#FF8F60"
      }

      if(num == syn3){
        return "#FFAA16"
      }

      if(num == syn4){
        return "#5B86F2"
      }

      if(num == syn5){
        return "#B491FF"
      }
      
      
      return "";
    }

    function format(data){
      data = Number(data);
      return data.toLocaleString('fr-FR');
    }
    

    return(
      <div>

    {this.state.aideFiltre && (
    <DialogAide titre={"Astuce"} open={true} close={this.closeAideFiltre}
    content={<span class="lato-regular" style={{fontSize:"15px"}}>
      <b>
      Synthèse Max : Analyse et Bilan ?
      </b>
      <br/><br/>
      Le module <b>Synthèse Max</b> permet d’analyser une synthèse, c’est-à-dire une combinaison de pronostics. Obtenez l'historique de la synthèse et consultez son bilan pour affiner vos stratégies de jeu.
     
      </span>}
    />
    )}

      <Card>
      <CardHeader
          avatar={
          <Avatar aria-label="recipe">
              S
          </Avatar>
          }
          action={
          <div>
              <HelpOutlineOutlinedIcon onClick={this.aideFiltre}  style={{color:"black", margin:"0.3em", cursor:"pointer"}}/>
          </div>
          }
          title={<span class="lato-bold" style={{transform:"none", color:"black", fontSize:"15px"}}>Synthèse Max</span>}
          subheader={<span class="lato-regular" style={{fontSize:"14px"}}>Module d'analyse Premium</span>}
      />
      <CardContent>
        { this.state.syntheseMax == "N" && (
          <div>
          <center>
            <Button  variant="contained" style={{textTransform:"none"}} onClick={() => (window.open("https://www.paypal.com/ncp/payment/BH6AGLYEVHTVS", "_blank", "noopener,noreferrer"))}>Acheter le module</Button>
          </center>
          <Alert style={{margin:"1em"}} severity="info"><span style={{fontSize:"14px"}} class="lato-regular">Avec Synthèse Max testez la performance de votre synthèse. Obtenez rapidement l'historique.
            <a href="https://youtu.be/CyHNTlsTZvA?si=eWmU2qsrcjsBJ5Pj" target="_blank"> vidéo de présentation</a></span></Alert>
          </div>
        )}

        { this.state.syntheseMax == "O" && (
          <center>
            <Button 
            disabled={this.state.dossier == "Pronostics Trotstats" ? true : false}
            variant="contained"
            style={{textTransform:"none"}}
            onClick={() => this.showModule()}
            >Analyser la Synthèse</Button>
          </center>
        )}
       
      </CardContent>
      </Card>

      <Dialog
      fullWidth
      open={this.state.showModule}
      maxWidth={"lg"}
      onClose={this.closeModule}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <span class="lato-regular">Synthèse Max</span>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={this.closeModule}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
      <CloseIcon style={{color:"black"}} />
      </IconButton>
      <Divider/>
      <DialogContent>
        <Table component={Paper}>
          <TableHead>
            <TableRow>
              <TableCell><span class="lato-bold">Performance de la synthèse (3 dern. mois)</span></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.bilan != null && (
              <div>
              <TableRow>
                <TableCell width={"100%"} align="left"><span class="lato-regular">Nombre de synthèse :</span></TableCell>
                <TableCell width={"100%"} align="right" ><span class="lato-regular">{selection(this.state.bilan.nombreSynthese)}</span></TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"100%"} align="left"><span class="lato-regular">Taux de réussite du cheval en tête vainqueur :</span></TableCell>
                <TableCell width={"100%"} align="right"><span class="lato-regular">{reussite(this.state.bilan.reussiteChevalTeteGagnant)}</span></TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"100%"} align="left"><span class="lato-regular">Taux de réussite du cheval en tête dans le Top 3 :</span></TableCell>
                <TableCell width={"100%"} align="right"><span class="lato-regular">{reussite(this.state.bilan.reussiteChevalTetePlace)}</span></TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"100%"} align="left"><span class="lato-regular">Taux de synthèses avec le gagnant :</span></TableCell>
                <TableCell width={"100%"} align="right"><span class="lato-regular">{reussite(this.state.bilan.reussiteSyntheseGagnant)}</span></TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"100%"} align="left"><span class="lato-regular">Taux de synthèses avec un cheval dans le Top 3 :</span></TableCell>
                <TableCell width={"100%"} align="right"><span class="lato-regular">{reussite(this.state.bilan.reussiteSynthesePlace)}</span></TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"100%"} align="left"><span class="lato-regular">Taux de synthèses avec 2 chevaux dans le Top 3 :</span></TableCell>
                <TableCell width={"100%"} align="right"><span class="lato-regular">{reussite(this.state.bilan.reussiteSynthese2Place)}</span></TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"100%"} align="left"><span class="lato-regular">Taux de synthèses avec 3 chevaux dans le Top 3 :</span></TableCell>
                <TableCell width={"100%"} align="right"><span class="lato-regular">{reussite(this.state.bilan.reussiteSynthese3Place)}</span></TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"100%"} align="left"><span class="lato-regular">Taux de synthèses (en trois chevaux) avec le gagnant :</span></TableCell>
                <TableCell width={"100%"} align="right"><span class="lato-regular">{reussite(this.state.bilan.reussite3Gagnant)}</span></TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"100%"} align="left"><span class="lato-regular">Taux de synthèses (en trois chevaux) avec un cheval dans le Top 3 :</span></TableCell>
                <TableCell width={"100%"} align="right"><span class="lato-regular">{reussite(this.state.bilan.reussite3Place)}</span></TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"100%"} align="left"><span class="lato-regular">Taux de synthèses (en trois chevaux) avec 2 chevaux dans le Top 3 :</span></TableCell>
                <TableCell width={"100%"} align="right"><span class="lato-regular">{reussite(this.state.bilan.reussite3Place2)}</span></TableCell>
              </TableRow>
              <TableRow>
                <TableCell width={"100%"} align="left"><span class="lato-regular">Taux de synthèses (en trois chevaux) avec 3 chevaux dans le Top 3 :</span></TableCell>
                <TableCell width={"100%"} align="right"><span class="lato-regular">{reussite(this.state.bilan.reussite3Place3)}</span></TableCell>
              </TableRow>
              </div>
            )}
              
          </TableBody>
        </Table>
        
        <div style={{marginTop:"2em"}}>
          <center>
            <span class="lato-bold">Historique des synthèses</span>
          </center>
        </div>
        <Table style={{marginTop:"1em"}} component={Paper}>
                    
          <TableRow>
            <TableCell width={"100px"}><span class="lato-bold">Date</span></TableCell>
            <TableCell width={"200px"}><span class="lato-bold">Hippodrome</span></TableCell>
            <TableCell width={"70px"}><span class="lato-bold">Course</span></TableCell>
            <TableCell width={"400px"}><span class="lato-bold">Synthèse</span></TableCell>
            <TableCell width={"400px"}><span class="lato-bold">Arrivée</span></TableCell>
          </TableRow>
       
          
          {this.state.bilan != null && this.state.bilan.syntheses != null && this.state.bilan.syntheses.map((synthese) => (
            <TableRow>
            <TableCell>
              <span class="lato-regular">{synthese.date}</span>
            </TableCell>
            <TableCell>
              <span class="lato-regular" style={{fontSize:"13px"}}>{synthese.hippodrome}</span>
            </TableCell>
            <TableCell>
              <span class="lato-regular">C{synthese.numCourse}</span>
            </TableCell>
            <TableCell>
            <div style={{display:"flex"}}>
                {synthese.synthese.syntheseParPoints[0] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge badgeContent={(synthese.synthese.syntheseParPoints[0].points)} color="primary">
                         <Avatar sx={{cursor:"pointer", bgcolor: "#66B230"}} >{synthese.synthese.syntheseParPoints[0].num}</Avatar>
                        </Badge>
                      </div>
                    )}

                {synthese.synthese.syntheseParPoints[1] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge badgeContent={Number(synthese.synthese.syntheseParPoints[1].points)} color="primary">
                         <Avatar sx={{cursor:"pointer", bgcolor: "#FF8F60"}} >{synthese.synthese.syntheseParPoints[1].num}</Avatar>
                        </Badge>
                      </div>
                    )}

                {synthese.synthese.syntheseParPoints[2] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge badgeContent={Number(synthese.synthese.syntheseParPoints[2].points)} color="primary">
                         <Avatar sx={{cursor:"pointer", bgcolor: "#FFAA16"}} >{synthese.synthese.syntheseParPoints[2].num}</Avatar>
                        </Badge>
                      </div>
                    )}

                {synthese.synthese.syntheseParPoints[3] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge badgeContent={Number(synthese.synthese.syntheseParPoints[3].points)} color="primary">
                         <Avatar sx={{cursor:"pointer", bgcolor: "#5B86F2"}} >{synthese.synthese.syntheseParPoints[3].num}</Avatar>
                        </Badge>
                      </div>
                    )}

                {synthese.synthese.syntheseParPoints[4] != null && (
                      <div style={{marginLeft:"1em"}}>
                        <Badge badgeContent={Number(synthese.synthese.syntheseParPoints[4].points)} color="primary">
                         <Avatar sx={{cursor:"pointer", bgcolor: "#B491FF"}} >{synthese.synthese.syntheseParPoints[4].num}</Avatar>
                        </Badge>
                      </div>
                    )}
              </div>

            </TableCell>
            <TableCell>

            <div style={{display:"flex"}}>
                  {synthese.classement
                    .split("-")
                    .slice(0, 5) 
                    .map((item, index) => (
                    <Avatar sx={{cursor:"pointer", marginRight:"0.5em", bgcolor: color(item, synthese.synthese.syntheseParPoints[0] != null ? synthese.synthese.syntheseParPoints[0].num : "", synthese.synthese.syntheseParPoints[1] != null ? synthese.synthese.syntheseParPoints[1].num : "", synthese.synthese.syntheseParPoints[2] != null ? synthese.synthese.syntheseParPoints[2].num : "", synthese.synthese.syntheseParPoints[3] != null ? synthese.synthese.syntheseParPoints[3].num : "", synthese.synthese.syntheseParPoints[4] != null ? synthese.synthese.syntheseParPoints[4].num : "")}}>{item}</Avatar>
                  ))}
                  </div>

            </TableCell>
            </TableRow>
          ))}

           
        </Table> 
       
      </DialogContent>
      
      </Dialog>

      <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

      </div>


    )
  }
}