import React, { Component } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridLoadingOverlay } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@mui/material/Avatar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Button from '@mui/material/Button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Grid from '@mui/material/Grid';
import { deepOrange, green } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import { frFR } from '@mui/material/locale';


import Pagination from "@mui/material/Pagination";
import {
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector
} from "@mui/x-data-grid";

export default class TableauHistorique extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:this.props.data,
    };
    
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.data})
  }

  componentDidMount() {
  }

  /************************************************************************************
   * RENDU
   ***********************************************************************************/

  render() {

    function CustomPagination() {
      const apiRef = useGridApiContext();
      const page = useGridSelector(apiRef, gridPageSelector);
      const pageCount = useGridSelector(apiRef, gridPageCountSelector);
    
      return (
        
          <Grid container spacing={2}>
          <Grid item xs={8}>
            
          </Grid>
          <Grid item xs={4}>

              <Pagination
              style={{float:"right"}}
              color="primary"
              count={pageCount}
              page={page + 1}
              onChange={(event, value) => apiRef.current.setPage(value - 1)}
              />

          </Grid>
          </Grid>
      );
    }

    function sg(data){
      if(data > 0){
        return (
          <Tooltip title={"Rapport cumulé : Simple Gagnant"}>
                <Avatar
                  style={{backgroundColor: "#3ca64a" , width: "45px", height: "25px", marginLeft:"12px" }}
                  variant="square"
                >
                  
                    <span style={{ fontVariantCaps: "small-caps", fontSize: "14px", color: "white" }}>{data}</span>
                </Avatar>
                </Tooltip>
        )
      }else{
        return (
          <center>
            <span>-</span>
          </center>
          
        )
      }
    }

    function sp(data){
      if(data > 0){
        return (
          <Tooltip title={"Rapport cumulé : Simple Placé"}>
                <Avatar
                  style={{backgroundColor: "#0089e9" , width: "45px", height: "25px", marginLeft:"12px" }}
                  variant="square"
                >
                  
                    <span style={{ fontVariantCaps: "small-caps", fontSize: "14px", color: "white" }}>{data}</span>
                </Avatar>
                </Tooltip>
        )
      }else{
        return (
          <center>
            <span>-</span>
          </center>
          
        )
      }
      
    }

    function pronostics(paris){
      let list = new Array();

      if(paris[0] != null){
        list.push(paris[0])
      }

      if(paris[1] != null){
        list.push(paris[1])
      }
      if(paris[2] != null){
        list.push(paris[2])
      }
      if(paris[3] != null){
        list.push(paris[3])
      }
      if(paris[4] != null){
        list.push(paris[4])
      }
      if(paris[5] != null){
        list.push(paris[5])
      }
      if(paris[6] != null){
        list.push(paris[6])
      }
      if(paris[7] != null){
        list.push(paris[7])
      }

      if (list != null) {
        return (
          <div style={{ display: 'flex' }}>
            {list.map((pronostic, index) => (
              <Tooltip title={pronostic.cheval}>
              <Avatar
                key={index}
                style={{ margin:"0.25em", backgroundColor: pronostic.arrivee == 1 ? "#3ca64a" : pronostic.arrivee == "2" || pronostic.arrivee == "3" ? "#0089e9" : "grey", width: "45px", height: "25px", marginLeft:"12px" }}
                variant="square"
              >
                
                  <span style={{ fontVariantCaps: "small-caps", fontSize: "14px", color: "white" }}>{pronostic.numPartant}</span>
              </Avatar>
              </Tooltip>
            ))}
          </div>
        );
      } else {
        return <span></span>;
      }

    }



    const columns = [
      { 
        field: 'date',
        headerName:  <span class="lato-regular">Date</span>,
        description: "#",
        width: 100,
        renderCell: (params) => (
          <span class="lato-regular">{params.formattedValue}</span>
         
        )
      },
      { 
        field: 'hippodrome',
        headerName:  <span class="lato-regular">Hippodrome</span>,
        description: "#",
        width: 250,
        renderCell: (params) => (
          <span class="lato-regular">{params.formattedValue}</span>
         
        )
      },
      { 
        field: 'heure',
        headerName:  <span class="lato-regular">Heure</span>,
        description: "#",
        width: 60,
        renderCell: (params) => (
          <span class="lato-regular">{params.formattedValue}</span>
         
        )
      },
      { 
        field: 'course',
        headerName:  <span class="lato-regular">Course</span>,
        description: "#",
        width: 100,
        renderCell: (params) => (
          <span class="lato-regular">C{params.formattedValue}</span>
         
        )
      },
      { 
        field: ' ',
        headerName:  <span class="lato-regular">Chevaux sélectionnés</span>,
        description: "#",
        width: 502,
        renderCell: (params) => (
          pronostics(params.row.paris)
        )
      },

      { 
        field: 'sg',
        headerAlign: 'center',
        headerName:  <span class="lato-regular">Sg</span>,
        description: "#",
        width: 80,
        renderCell: (params) => (
          sg(params.formattedValue)
        )
      },

      { 
        field: 'sp',
        headerAlign: 'center',
        headerName:  <span class="lato-regular">Sp</span>,
        description: "#",
        width: 80,
        renderCell: (params) => (
          sp(params.formattedValue)
        )
      },
     
      
  ]


    return(
      
      <DataGrid
      localeText={frFR.localeText}
      density="compact"
      hideFooter={false}
      disableRowSelectionOnClick
      rows={this.state.data}
      columns={columns}
      
      initialState={{
        pagination: { paginationModel: { pageSize: 20 } },
      }}
      rowsPerPageOptions={[]}
     />

    )
  }
}